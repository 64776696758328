<template>
  <section class="in-cabin-warpper">
    <div class="banner">
      <ImageWithPlaceholder
        src="/image/in-cabin/incabin.png"
        alt="In-cabin Visual Software Solutions"
      />
      <div class="in-cabin-box">
        <h1 class="in-cabin-title">In-cabin Visual Software Solutions</h1>
        <div class="item-list">
          <a href="#dms" class="dms">DMS</a>
          <a href="#dhs" class="dhs">DHS</a>
          <a href="#face" class="face">Face ID</a>
          <a href="#oss" class="safety"
            ><span>Occupant</span><span> Safety</span></a
          >
          <a href="#soc" class="integrated">
            <span>Integrated </span><span>ECU Solutions</span>
          </a>
        </div>
      </div>
    </div>
    <div id="dms" class="content dms-warpper pt120">
      <h2 class="dms-title h2-title">Driver Monitoring System (DMS)</h2>
      <p class="p-text">
        As one of the leading Driver Monitoring System (DMS) software providers,
        ArcSoft helped more than 10 automotive OEMs mass-releasing more than 52
        models with DMS functions. ArcSoft DMS solutions have passed the EU DDAW
        and ADDW validation tests, working with Tier 1 partners to help OEMs
        meet the regulatory requirements for ADDW, DDAW, E-NCAP, and Chinese GB
        regulations.
      </p>
      <p class="p-text">
        ArcSoft not only provides OEMs with DMS software, but also expertise and
        test services for pre-certification tests and formal certification
        tests.
      </p>
    </div>
    <div class="dms-supports-warpper mt50">
      <div class="supports-top">
        <div class="content">
          <div class="supports supports-box">Supports</div>
          <h3 class="soc-platforms soc-platforms-white h3-title">
            SoC platforms
          </h3>
          <ul class="platform-list mb20">
            <li class="qualcomm">SA8155P, SA8295P, SA8620P etc</li>
            <li class="medntek">MT8666, MT8675, MT2715 etc</li>
            <li class="nvidia">Xavier, Orin</li>
            <li class="texas">TDA4, AM62x, TDA3 etc</li>
            <li class="omnivision">OAX8000, OAX4600</li>
          </ul>
          <h3 class="camera-locations-white h3-title">Camera locations</h3>
        </div>
        <ul class="img-list">
          <li>
            <img src="/image/in-cabin/dms1.png" alt="A-pillar" />
          </li>
          <li>
            <img src="/image/in-cabin/dms2.png" alt="Steering column" />
          </li>
          <li>
            <img src="/image/in-cabin/dms3.png" alt="Dashboard" />
          </li>
          <li>
            <img src="/image/in-cabin/dms4.png" alt="Rearview mirror" />
          </li>
          <li>
            <img src="/image/in-cabin/dms5.png" alt="Overhead area" />
          </li>
        </ul>
      </div>
      <div class="supports-bottom content mt120">
        <div>
          <h3 class="sensors-black h3-title">Sensors</h3>
          <p class="p-text bottom-text">
            All sensors from 1M to 8M are supported, including IR sensors for
            DMS functions, RGB/IR sensors for OMS functions.
          </p>
          <h3 class="gsr-black h3-title">Regulations</h3>
          <p class="p-text bottom-text">
            Support DDAW, ADDW, GBT, E-NCAP, A-NCAP, CN-NCAP, C-IASI.
          </p>
        </div>
        <div class="bottom-img">
          <img src="/image/in-cabin/regulationpaper.png" />
        </div>
      </div>
    </div>
    <div id="dhs" class="dhs-warpper mt50">
      <div class="dhs-warpper-bg-box">
        <img
          class="dhs-warpper-bg"
          src="/image/in-cabin/incabin2.png"
          alt="Driver Health System (DHS)"
        />
      </div>
      <div class="content pt80">
        <h2 class="dhs-title h2-title">Driver Health System (DHS)</h2>
        <p class="p-text dhs-text mt30">
          In addition to detecting driver drowsiness and inattention, ArcSoft
          can also use the DMS camera to create advanced features to help
          drivers with personalized features and safer driving, such as the
          Driver Health System (DHS).
        </p>

        <p class="p-text dhs-text">
          ArcSoft DHS is an innovative in-cabin intelligent sensing technology
          designed to collect, analyze, and interpret the driver's vital
          physiological signs based on remote photoplethysmography (rPPG). Vital
          signs are extracted from the corresponding light absorption changes in
          the characteristics of arterial blood flow in the driver's facial skin
          captured with the DMS camera.
        </p>
        <p class="p-text dhs-text">
          OEMs can integrate ArcSoft DHS with advanced features such as
          detection and monitoring of driver physical state, checking if a
          driver is in a good health state to take on and maintain the driving
          task, and make personalized air-conditioning adjustment, music
          playing, and other in-cabin environment controls.
        </p>
        <div class="supports-warpper">
          <div class="supports supports-box">Supports</div>
          <h3 class="soc-platforms-black h3-title mt30">SoC platforms</h3>
          <p class="p-text">Qualcomm (SA8155P, SA8295P, SA8775P etc)</p>
          <p class="p-text">MTK (MT8666, MT8675, MT2715 etc)</p>
          <p class="p-text">Nvidia (Xavier, Orin)</p>
          <p class="p-text">TI (TDA4, AM62x, TDA3 etc)</p>
          <h3 class="camera-locations-black h3-title mt20">Camera locations</h3>
          <p class="p-text">
            A-pillar, or steering column, or dashboard, or rearview mirror, or
            overhead area.
          </p>
          <h3 class="sensors-black h3-title mt20">Sensors</h3>
          <p class="p-text">
            All IR or RGB sensors from 1M to 8M are supported.
          </p>
          <h3 class="supporting-black h3-title mt20">Features</h3>
          <ul class="health-list">
            <li>Heart Rate</li>
            <li>Heart Rate Variability</li>
            <li>Blood Oxygen Saturation</li>
            <li>Blood Pressure</li>
            <li>Breathing Rate</li>
            <li>Physical Energy</li>
            <li>Dynamic Health Detection</li>
            <li>Stress Detection</li>
          </ul>
        </div>
      </div>
    </div>
    <div id="face" class="face-warpper pt80">
      <div class="content">
        <h2 class="face-title h2-title">Face ID and Facial Recognition</h2>
        <div class="face-top mt30">
          <div class="face-t-l">
            <p class="p-text face-t-l-text">
              With its extensive facial detection, recognition, and tracking
              technologies, ArcSoft can re-use the DMS camera or OMS camera to
              help automotive OEMs to create advanced personalized features such
              as
            </p>
            <p class="p-text border-l">
              * enhanced car safety for anti-theft with driver Face ID as an
              added authorization method, where authorized driver info are
              stored locally in the vehicle, and facial recognition is performed
              after ignition to make sure the driver is authorized to drive;
            </p>
            <p class="p-text border-l">
              * value-added services of personal settings, where Face ID is used
              to adjust personalized seat positions, steering wheel positions,
              infotainment preferences, and climate control settings.
            </p>
            <p class="p-text face-t-l-text">
              In addition, ArcSoft can help OEMs to implement Face ID functions
              in other positions, such as on the outside B-pillars, for
              authorized entry into the vehicles.
            </p>
            <p class="p-text face-t-l-text">
              We understand the importance of privacy and security, the above
              methods do not save face images, but encrypted face feature
              information in the car, to ensure that the biometric information
              is safe and secure, helping OEMs to conform to GDPR requirements.
            </p>
          </div>
          <div class="face-t-r">
            <img
              src="/image/in-cabin/faceid.png"
              alt="Face ID and Facial Recognition"
            />
          </div>
        </div>
      </div>
      <div class="face-bottom">
        <div class="face-b-r content">
          <div class="face-b-l">
            <img
              src="/image/in-cabin/incabin3.png"
              alt="Face ID and Facial Recognition"
            />
          </div>
          <div class="face-b-r-r">
            <div class="supports supports-box">Supports</div>
            <h3 class="soc-platforms-white h3-title mt30">SoC platforms</h3>
            <p class="p-text face-b-r-text">
              Qualcomm (SA8155P, SA8295P, SA8775P etc)
            </p>
            <p class="p-text face-b-r-text">MTK (MT8666, MT8675, MT2715 etc)</p>
            <p class="p-text face-b-r-text">Nvidia (Xavier, Orin)</p>
            <p class="p-text face-b-r-text">TI (TDA4, AM62x, TDA3 etc).</p>
            <h3 class="camera-locations-white h3-title">Camera locations</h3>
            <p class="p-text face-b-r-text">
              A-pillar, or steering column, or dashboard, or rearview mirror, or
              overhead area.
            </p>
            <h3 class="sensors-white h3-title">Sensors</h3>
            <p class="p-text face-b-r-text">
              All IR or RGB sensors from 1M to 8M are supported.
            </p>
            <div class="node-tree-list">
              <div class="node-tree">
                <h4>Face detection</h4>
              </div>
              <div class="node-tree">
                <h4>Anti-spoofing</h4>
                <p>pictures</p>
                <p>masks</p>
                <p>3D</p>
              </div>
              <div class="node-tree">
                <h4>Face recognition</h4>
                <p>driver ID</p>
                <p>pay with face ID</p>
                <p>smart cabin setting</p>
              </div>
              <div class="node-tree">
                <h4>Face properties</h4>
                <p>gender</p>
                <p>age</p>
              </div>

              <div class="node-tree">
                <h4>Emotion detection</h4>
                <p>happy</p>
                <p>sad</p>
                <p>angry</p>
                <p>nervous</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div id="oss" class="oss-warpper pt80">
      <div class="content">
        <h2 class="oss-title h2-title">Occupant Safety System (OSS)</h2>
        <p class="p-text mt30">
          ArcSoft advanced occupant safety visual sensing technologies (ArcSoft
          Occupant Safety System, OSS) can be important supplements to existing
          driver and passenger safety systems. For example, ArcSoft visual
          Occupant Safety System, can help automotive OEMs to achieve higher
          accuracy and efficiency for the functions:
        </p>
        <p class="p-text right">
          SOR (Seat Occupancy Recognition), where ArcSoft visual algorithms are
          used to detect where the passengers are seated in the car, and their
          body posture state, size, gender, age information can also be analyzed
          if interested.
        </p>
        <p class="p-text right">
          SBR (Seat Belt Reminder), where ArcSoft visual algorithms are used to
          detect whether the seatbelt is correctly worn by the driver or
          passengers.
        </p>
        <p class="p-text right">
          CPD (Child Presence Detection), where ArcSoft visual algorithms are
          used to detect whether there is a child or children alone in the
          vehicles without adult supervision, enabling OEMs to take preventive
          methods to avoid injury to them.
        </p>
        <p class="p-text">
          ArcSoft OSS can be a very important supplement to normal in-cabin
          safety mechanisms, especially on longtail and corner cases, such as
          mis-classifying luggage as a passenger.
        </p>
        <img
          class="oss-img mt30"
          src="/image/in-cabin/incabin4.png"
          alt="Occupant Safety System (OSS)"
        />
        <div class="oss-supports-box mt50 pb50">
          <div class="supports supports-box">Supports</div>
          <h3 class="soc-platforms-black h3-title mt30">SoC platforms</h3>
          <p class="p-text">Qualcomm (SA8155P, SA8295P, SA8775P etc)</p>
          <p class="p-text">MTK (MT8666, MT8675, MT2715 etc)</p>
          <p class="p-text">Nvidia (Xavier, Orin)</p>

          <h3 class="camera-locations-black h3-title mt20">Camera locations</h3>
          <p class="p-text">Rearview mirror, or overhead areas.</p>
          <h3 class="sensors-black h3-title mt20">Sensors</h3>
          <p class="p-text">
            Depending on specific functions, can support 5M or 8M
          </p>
          <p class="p-text">pixels sensors.</p>
          <div class="oss-supports-bg">
            <img
              src="/image/in-cabin/incabin5.png"
              alt="Occupant Safety System (OSS)"
            />
          </div>
        </div>
      </div>
    </div>
    <div id="soc" class="soc-warpper mt50 pb50">
      <div class="soc-warpper-bg-box">
        <img
          class="soc-warpper-bg"
          src="/image/in-cabin/incabin6.png"
          alt="Driver Health System (DHS)"
        />
      </div>
      <div class="content pt80">
        <h2 class="soc-title">
          Integrated In-cabin Visual ECU on a Simplified SoC
        </h2>
        <p class="p-text soc-text mt30">
          ArcSoft in-cabin DMS and OMS features are already being mass-released
          on the Qualcomm IVI SoCs and other platforms. However, some vehicles
          may lack computing power but still need to meet the regulatory
          requirements. To help with this, ArcSoft offers low cost and single
          purpose integrated in-cabin visual ECU solutions based on OAX8000 or
          OAX4600, product code name "Tahoe".
        </p>
        <p class="p-text soc-text">
          ArcSoft Tahoe 1.0 is based on OAX8000 and supports single camera for
          DMS functions of DDAW, ADDW, and Face ID. Tahoe 2.0 is based on
          OAX4600 and supports up to 4 cameras with additional functions such as
          driver health system (DHS), HOD, SBR, hand gesture recognition (HGR),
          passenger seat position detection, and CPD. Tahoe 2.0 also meets
          ASIL-B functional safety requirements.
        </p>
        <p class="p-text soc-text">
          Tahoe is an all-in-one standalone integrated visual solution for DMS
          or OMS integrated with camera and process unit in one box. With DDR
          embedded inside the SoC, Tahoe has size almost as small as the
          thumbnail. In addition, it has the advantages of low power
          consumption, and flexible installation locations. Tahoe has been
          validated to meet the regulatory requirements of DDAW, ADDW, ENCAP
          DSM, and Chinese GB regulations, helping OEMs to implement the
          necessary functions with shorter development time and without complex
          system modifications.
        </p>
        <p class="p-text soc-text">
          With their unique simplified SoC configurations, the Tahoe 1.0 ECU
          offers OEMs with very economical single purpose DMS solution, while
          Tahoe 2.0 ECU offers a combination of DMS and OMS functions with
          cost-effective and high performance results.
        </p>
        <div class="soc-list mt30">
          <span></span>
          <span class="point">Tahoe</span>
          <span class="point">Tahoe 2.0</span>
          <span class="point">Platform</span>
          <span>OAX8000</span>
          <span>OAX4600</span>

          <span class="point">Camera</span>
          <span>1</span>
          <span>up to 4</span>
          <span class="point">Functional Safety</span>
          <span>QM</span>
          <span>ASIL-B</span>
          <span class="point">Resolution</span>
          <span>1 or 2 Million pixels</span>
          <span>up to 5 Million pixels</span>
          <span class="point">Embedded DDR</span>
          <span>128MB</span>
          <span>256MB</span>
          <span class="point">Communication</span>
          <span>CAN/CAN FD</span>
          <span>CAN/CAN FD + Ethernet</span>
        </div>
        <h3 class="camera-locations-black h3-title mt30">Camera locations</h3>
        <p class="p-text">For DMS: A-pillar, steering column, dashboard.</p>
        <p class="p-text">
          For OMS: rearview mirror, reading lights, roof module, or overhead
          area.
        </p>
        <h3 class="sensors-black h3-title">Sensors</h3>
        <p class="p-text">For DMS: 1M ~ 5M lR sensors.</p>
        <p class="p-text">For OMS: 2M ~ 5M IR or RGB-IR sensors.</p>
      </div>
    </div>
  </section>
</template>
<script>
import ImageWithPlaceholder from '@/components/ImageWithPlaceholder.vue';
export default {
  components: {
    ImageWithPlaceholder,
  },
  metaInfo: {
    title:
      'In-Cabin Visual Software Solutions: Driver Monitoring, Facial Recognition, and Occupant Safety Systems',
    meta: [
      {
        name: 'description',
        content: `Offering comprehensive in-cabin visual software solutions, including Driver Monitoring Systems (DMS), Driver Health Systems, Face ID and facial recognition technology, and occupant safety systems. Our integrated in-cabin visual ECU, powered by a simplified SoC, enhances safety and security for modern vehicles.`,
      },
      {
        name: 'keywords',
        content: `in-cabin visual software, Driver Monitoring System, Driver Health System, Face ID, facial recognition, occupant safety system, visual ECU, SoC, automotive safety solutions, vehicle cabin monitoring, driver assistance, smart vehicle technology，DMS, DHS`, // 关键词
      },
    ],
  },
};
</script>
<style lang="less" scoped>
.in-cabin-warpper {
  position: relative;
  background-color: #fff;
  // padding-top: 1px;
  .soc-platforms-white {
    color: #fff;
    background: url('/image/in-cabin/platform.svg') no-repeat;
    background-size: 24px 24px;
  }
  .camera-locations-black {
    color: #222;
    background: url('/image/in-cabin/cameralocation.svg') no-repeat;
    background-size: 24px 24px;
  }
  .camera-locations-white {
    background: url(/image/in-cabin/cameralocation1.svg) no-repeat;
    color: #fff;
    background-size: 24px 24px;
  }
  .sensors-black {
    color: #222222;
    background: url('/image/in-cabin/sensors.svg') no-repeat;
    background-size: 24px 24px;
  }
  .sensors-white {
    color: #fff;
    background: url('/image/in-cabin/sensors1.svg') no-repeat;
    background-size: 24px 24px;
  }
  .gsr-black {
    color: #222222;
    background: url('/image/in-cabin/regulation.svg') no-repeat;
    background-size: 24px 24px;
  }
  .soc-platforms-black {
    color: #222;
    background: url('/image/in-cabin/platform1.svg') no-repeat;
    background-size: 24px 24px;
  }
  .supporting-black {
    color: #222;
    background: url('/image/in-cabin/dhs.svg') no-repeat 2px center;
    background-size: 19px 17px;
  }
  .p-text {
    color: #222;
    line-height: 18px;
    padding: 0 32px;
    margin: 10px 0;
  }
  .h2-title::before {
    content: '';
    display: inline-block;
    width: 4px;
    height: 40px;
    background: #e83021;
    vertical-align: bottom;
    margin-right: 24px;
  }
  .h3-title {
    height: 24px;
    line-height: 24px;
    font-size: 18px;
    text-indent: 32px;
    font-weight: normal;
    margin-bottom: 10px;
  }
  .supports-box {
    width: 109px;
    height: 41px;
    background-color: #e83021;
    border-radius: 2px;
    color: #fff;
    font-size: 18px;
    line-height: 41px;
    text-align: center;
  }
  .content {
    width: 1000px;
    box-sizing: border-box;
    margin: 0 auto;
    position: relative;
    z-index: 2;
    padding-top: 0.1px;
    padding-left: 20px;
    padding-right: 20px;
  }
  .banner {
    width: 100%;
    position: relative;
    .in-cabin-box {
      margin: 0 auto;
      position: absolute;
      z-index: 5;
      bottom: -60px;
      left: calc(50% - 500px);
      .in-cabin-title {
        font-weight: normal;
        font-size: 54px;
        color: #fff;
        text-align: center;
      }
      .item-list {
        margin-top: 246px;
        height: 120px;
        display: inline-flex;
        box-sizing: border-box;
        background: linear-gradient(to bottom, #eaeaeb, #fff);
        padding: initial;
        a {
          font-size: 20px;
          width: 200px;
          list-style: none;
          border: 1px solid #d9d9d9;
          border-left: initial;
          box-sizing: border-box;
          padding: 15px 25px;

          position: relative;

          span {
            display: inline-block;
            width: 150px;
          }
          &:hover::after {
            display: block;
          }
          &:hover {
            color: #e83021;
          }
        }
        a:first-child {
          border-left: 1px solid #d9d9d9;
        }
        .dms {
          background: url(/image/in-cabin/dms.svg) no-repeat 85% 15px e('/')
              22px 22px,
            url(/image/in-cabin/go.svg) no-repeat 25px 80px e('/') 22px 22px;
          &::after {
            content: '';
            position: absolute;
            width: 100%;
            height: 100%;
            left: 0;
            top: 0;
            display: none;
            background: url(/image/in-cabin/dms-action.svg) no-repeat 85% 15px
                e('/') 22px 22px,
              url(/image/in-cabin/go-action.svg) no-repeat 25px 80px e('/') 22px
                22px;
          }
        }
        .dhs {
          background: url(/image/in-cabin/dhs.svg) no-repeat 85% 18px e('/')
              19px 18px,
            url(/image/in-cabin/go.svg) no-repeat 25px 80px e('/') 22px 22px;
          &::after {
            content: '';
            position: absolute;
            width: 100%;
            height: 100%;
            left: 0;
            top: 0;
            display: none;
            background: url(/image/in-cabin/dhs-action.svg) no-repeat 85% 18px
                e('/') 19px 18px,
              url(/image/in-cabin/go-action.svg) no-repeat 25px 80px e('/') 22px
                22px;
          }
        }
        .face {
          background: url(/image/in-cabin/faceid.svg) no-repeat 85% 16px e('/')
              22px,
            url(/image/in-cabin/go.svg) no-repeat 25px 80px e('/') 22px 22px;
          background-size: 22px 22px;
          &::after {
            content: '';
            position: absolute;
            width: 100%;
            height: 100%;
            left: 0;
            top: 0;
            display: none;
            background: url(/image/in-cabin/faceid-action.svg) no-repeat 85%
                16px e('/') 22px,
              url(/image/in-cabin/go-action.svg) no-repeat 25px 80px e('/') 22px
                22px;
          }
        }
        .safety {
          background: url(/image/in-cabin/occupant.svg) no-repeat 85% 16px
              e('/') 22px,
            url(/image/in-cabin/go.svg) no-repeat 25px 80px e('/') 22px;
          &::after {
            content: '';
            position: absolute;
            width: 100%;
            height: 100%;
            left: 0;
            top: 0;
            display: none;
            background: url(/image/in-cabin/occupant-action.svg) no-repeat 85%
                16px e('/') 22px,
              url(/image/in-cabin/go-action.svg) no-repeat 25px 80px e('/') 22px;
          }
        }
        .integrated {
          background: url(/image/in-cabin/integratedsolution.svg) no-repeat 85%
              16px e('/') 22px,
            url(/image/in-cabin/go.svg) no-repeat 25px 80px e('/') 22px;
          &::after {
            content: '';
            position: absolute;
            width: 100%;
            height: 100%;
            left: 0;
            top: 0;
            display: none;
            background: url(/image/in-cabin/integratedsolution-action.svg)
                no-repeat 85% 16px e('/') 22px,
              url(/image/in-cabin/go-action.svg) no-repeat 25px 80px e('/') 22px;
          }
        }
      }
    }
  }
  .dms-warpper {
    .dms-title {
      color: #222222;
      font-size: 40px;
      font-weight: normal;
    }
  }
  .dms-supports-warpper {
    background-color: #fff;
    .supports-top {
      background-color: #222222;
      height: 400px;
      display: flex;
      flex-direction: column;
      .supports {
        position: absolute;
        left: 20px;
        top: -20px;
      }
      .soc-platforms {
        margin-top: 45px;
      }
      .platform-list {
        margin: initial;
        height: 100px;
        width: 100%;
        display: inline-flex;
        box-sizing: border-box;
        background: linear-gradient(to bottom, #3a3a3a, #535353);
        padding: initial;
        li {
          width: 20%;
          list-style: none;
          border: 1px solid #d9d9d9;
          border-left: initial;
          box-sizing: border-box;
          text-align: center;
          font-size: 12px;
          color: #fafafa;
          padding-top: 70px;
          box-sizing: border-box;
        }
        li:first-child {
          border-left: 1px solid #d9d9d9;
        }
        .qualcomm {
          background: url(/image/in-cabin/qualcomm.png) no-repeat center 30px;
          background-size: 118px 25px;
        }
        .medntek {
          background: url(/image/in-cabin/medntek.png) no-repeat center 25px;
          background-size: 106px 28px;
        }
        .nvidia {
          background: url(/image/in-cabin/nvidia.png) no-repeat center 30px;
          background-size: 121px 23px;
        }
        .texas {
          background: url(/image/in-cabin/texas.png) no-repeat center 20px;
          background-size: 100px 37px;
        }
        .omnivision {
          background: url(/image/in-cabin/omnivision.png) no-repeat center 30px;
          background-size: 146px 19px;
        }
      }
      .introduce {
        text-align: right;
        font-size: 14px;
        margin: 20px 0;
        color: #fff;
      }
      .img-list {
        margin: 20px auto;
        display: flex;
        list-style: none;
        padding: initial;
        li {
          margin-left: 15px;
          aspect-ratio: 300 / 200;
          img {
            width: 100%;
          }
        }
        li:first-child {
          margin-left: initial;
        }
      }
    }
    .supports-bottom {
      display: flex;
      justify-content: space-between;

      // background-image: url('/image/in-cabin/regulationpaper.png');
      // background-repeat: no-repeat;
      // background-position: 910px 150px;
      // background-size: 500px;
      .bottom-text {
        margin-bottom: 30px;
        width: 280px;
        color: #222222;
      }
      .bottom-img {
        aspect-ratio: 514 / 224;
        margin-left: 80px;
        img {
          width: 100%;
        }
      }
    }
  }
  .dhs-warpper {
    background-color: #fff;
    position: relative;
    .dhs-warpper-bg-box {
      background-color: #222222;
      width: 100%;
      height: 560px;
      position: absolute;
      .dhs-warpper-bg {
        position: absolute;
        top: 0;
        right: 0;
        height: 100%;
        img {
          height: 100%;
        }
      }
    }
    .dhs-title {
      font-size: 40px;
      font-weight: normal;
      color: #fff;
    }
    .dhs-text {
      color: #fff;
      width: 570px;
    }
    .supports-warpper {
      margin-top: 50px;
      background-color: rgba(255, 255, 255, 0.9);
      backdrop-filter: blur(10px);
      padding: 34px 24px;
      padding-top: 20px;
      position: relative;
      box-sizing: border-box;
      border: solid 1px #d9d9d9;
      width: 950px;
      .supports {
        position: absolute;
        top: -20px;
        left: -1px;
      }
      .health-list {
        list-style: none;
        display: inline-flex;
        flex-wrap: wrap;
        padding: initial;
        li {
          width: 25%;
          height: 105px;
          color: #151515;
          font-size: 14px;
          text-align: center;
          padding-top: 90px;
          margin-bottom: 20px;
          box-sizing: border-box;
        }
        li:nth-child(1) {
          background: url('/image/in-cabin/heartrate.png') no-repeat center 5px;
          background-size: 80px 80px;
        }
        li:nth-child(2) {
          background: url('/image/in-cabin/variability.png') no-repeat center
            5px;
          background-size: 80px 80px;
        }
        li:nth-child(3) {
          background: url('/image/in-cabin/bloodoxygen.png') no-repeat center
            5px;
          background-size: 80px 80px;
        }
        li:nth-child(4) {
          background: url('/image/in-cabin/bloodpressure.png') no-repeat center
            5px;
          background-size: 80px 80px;
        }
        li:nth-child(5) {
          background: url('/image/in-cabin/breathing.png') no-repeat center 5px;
          background-size: 80px 80px;
        }
        li:nth-child(6) {
          background: url('/image/in-cabin/energy.png') no-repeat center 5px;
          background-size: 80px 80px;
        }
        li:nth-child(7) {
          background: url('/image/in-cabin/dynamic.png') no-repeat center 5px;
          background-size: 80px 80px;
        }
        li:nth-child(8) {
          background: url('/image/in-cabin/stress.png') no-repeat center 5px;
          background-size: 80px 80px;
        }
      }
    }
  }
  .face-warpper {
    .face-title {
      font-size: 40px;
      font-weight: normal;
      color: #222;
    }
    .face-top {
      display: inline-flex;
      justify-content: space-between;
      width: 100%;
      .face-t-l {
        flex: 1;

        .border-l {
          border-left: 2px solid #d9d9d9;
          padding-left: 20px;

          margin-left: 43px;
          margin-top: initial;
          margin-bottom: initial;
          padding-bottom: 10px;
          box-sizing: border-box;
        }
        p:nth-child(3) {
          padding-bottom: initial;
        }
        .face-t-l-text:first-child {
          margin-top: initial;
        }
      }
      .face-t-r {
        aspect-ratio: 382 / 416;
        img {
          width: 100%;
        }
      }
    }
    .face-bottom {
      margin-top: 20px;
      height: 657px;
      background-color: #222;
      display: flex;
      box-sizing: border-box;
      .face-b-r {
        position: relative;
        .face-b-r-r {
          width: 700px;
          position: absolute;
          left: 410px;
          top: 20px;
        }
        .face-b-l {
          position: absolute;
          top: -20px;
          left: -460px;
          height: 100%;
          img {
            height: calc(100% + 20px);
          }
        }
        .supports {
          position: absolute;
          left: 0;
          top: -40px;
        }
        .face-b-r-text {
          color: #fff;
        }
        .node-tree-list {
          overflow: hidden;
        }
        .node-tree {
          color: #fff;
          position: relative;
          margin-top: 5px;
          width: 33%;
          float: left;
          &::before {
            content: '';
            width: 1px;
            display: inline-block;
            height: calc(100% - 30px);
            background-color: #fff;
            position: absolute;
            top: 22px;
            left: 8.5px;
            opacity: 0.25;
          }
          h4 {
            font-weight: 500;
            // text-indent: 32px;
            line-height: 24px;
            height: 24px;

            &::before {
              content: '';
              margin-right: 5px;
              display: inline-block;
              width: 18px;
              height: 18px;
              vertical-align: bottom;
              background: url('/image/in-cabin/dot.svg') no-repeat center center;
            }
          }
          p {
            color: #a5a5a5;
            padding-top: 5px;
            &::before {
              content: '·';
              margin-right: 5px;
              width: 18px;
              height: 18px;
              text-align: center;
              display: inline-block;
              font-size: 18px;
              font-weight: bold;
              line-height: 18px;
              vertical-align: middle;
            }
          }
        }
      }
    }
  }
  .oss-warpper {
    .oss-title {
      color: #222222;
      font-size: 40px;
      font-weight: normal;
    }

    .right {
      padding: 0 52px;
      background: url('/image/in-cabin/arrow.svg') no-repeat 37px 5px;
    }
    .oss-img {
      width: 100%;
    }
    .oss-supports-box {
      width: 640px;
      padding: 26px;
      border: solid 1px #d9d9d9;
      position: relative;
      box-sizing: border-box;
      p {
        width: 75%;
      }
      .supports {
        position: absolute;
        left: -1px;
        top: -20px;
      }
      .oss-supports-bg {
        position: absolute;
        right: -319px;
        top: 54px;
        width: 440px;
        height: 293px;
        img {
          width: 100%;
        }
      }
    }
  }
  .soc-warpper {
    position: relative;
    .soc-warpper-bg-box {
      text-align: center;
      position: absolute;
      width: 100%;

      height: 591px;
      overflow: hidden;
      background: #222;
      .soc-warpper-bg {
        width: 100vw;
        min-width: 1440px;
      }
    }
    .soc-title {
      font-size: 40px;
      color: #fff;
      text-align: center;
    }
    .soc-title::after {
      content: '';
      width: 48px;
      height: 4px;
      display: block;
      background-color: #e83021;
      margin: 0 auto;
      margin-top: 25px;
    }
    .soc-text {
      color: #fff;
      line-height: 20px;
      padding: initial;
    }
    .soc-list {
      display: flex;
      flex-wrap: wrap;
      border: solid 1px #d9d9d9;
      .point {
        background: linear-gradient(to right, #393939, #545454);
      }
      span {
        width: 33.3%;
        box-sizing: border-box;
        background-color: #222222;
        border-right: solid 1px #d9d9d9;
        border-bottom: solid 1px #d9d9d9;
        padding: 8px 20px;
        color: #fff;
        font-size: 12px;
      }
      span:nth-child(3n) {
        border-right: initial;
      }
    }
  }
}
</style>
